import { ApplicationConfig, inject } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client/core';
import { envs } from '@shared/envs/env';
import { APOLLO_NAMED_OPTIONS, Apollo, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

export function apolloOptionsFactory(): NamedOptions {
  const httpLink = inject(HttpLink);

  const cache = new InMemoryCache({
    addTypename: true,
  });

  const apiLink = ApolloLink.from([
    httpLink.create({
      uri: envs.graphqlApiUrl,
    }),
  ]);

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  };

  const apiClient: ApolloClientOptions<any> = {
    cache,
    link: apiLink,
    defaultOptions,
  };

  return {
    default: apiClient,
    apiClient,
  };
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_NAMED_OPTIONS,
    useFactory: apolloOptionsFactory,
  },
];
