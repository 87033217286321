import { inject } from '@angular/core';
import type { CanActivateFn } from '@angular/router';
import { constantVariables } from '@shared/general/configs';
import { GeneralService } from '@shared/services/general.service';

export const accessGuard: CanActivateFn = (route, state) => {
  const generalService = inject(GeneralService);

  if (localStorage.getItem(constantVariables.refreshToken)) {
    return true;
  }
  generalService.router.navigateByUrl('/auth');
  return false;
};
